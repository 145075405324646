import {
  Box,
  Container,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Text,
  VStack,
} from "@chakra-ui/react";
import { ChevronDownIcon, ChevronDownIconRed } from "./icons/ChevronDown";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useState, useContext } from "react";
import { useLocation, Link } from "react-router-dom";
import { LanguajeContext } from "../App";

const Navegacion = () => {
  const [sideMenu, setSideMenu] = useState(false);
  const [desplegable, setDesplegable] = useState(false);
  const location = useLocation();
  const { lang, setLang } = useContext(LanguajeContext);
  return (
    <Container
      maxW="full"
      backgroundColor="transparent"
      position={"absolute"}
      top={"0"}
      width={"100%"}
      zIndex={"999"}
    >
      <Box py={5} px={{ md: 12, xs: 5 }}>
        <HStack justifyContent={"space-between"}>
          <Box
            display={{
              "2xl": "none",
              xl: "none",
              lg: "none",
              md: "block",
              sm: "block",
              xs: "block",
            }}
            zIndex={20}
          >
            <HamburgerIcon
              color={"#F8EFE7"}
              w={{ md: 7, xs: 5 }}
              h={{ md: 7, xs: 5 }}
              transition={"all .4s ease-in-out"}
              onClick={() => setSideMenu(true)}
            />
          </Box>
          <Box display={"flex"} justifyContent={"center"}>
            <Link to="/">
              <Image
                src="./img/logo-dorado.png"
                maxW={["20%", "50%", "100%", "100%", "100%", "100%"]}
                margin={"0 auto"}
                display={location.pathname === "/" ? "none" : "block"}
              />
            </Link>
          </Box>
          <HStack
            spacing="12"
            display={{
              "2xl": "flex",
              xl: "flex",
              lg: "flex",
              md: "none",
              sm: "none",
              xs: "none",
            }}
            color={
              location.pathname === "/contacto" ||
              location.pathname.includes("vino/")
                ? "#931414"
                : "#F8EFE7"
            }
            fontFamily={"Montserrat"}
            fontSize={"14px"}
            lineHeight={"17px"}
            fontWeight={"400"}
            textTransform={"uppercase"}
            letterSpacing={"0.01em"}
          >
            <Menu>
              <MenuButton
                as={Button}
                rightIcon={
                  location.pathname === "/contacto" ||
                  location.pathname.includes("vino/") ? (
                    <ChevronDownIconRed />
                  ) : (
                    <ChevronDownIcon />
                  )
                }
                bg="transparent"
                textTransform={"uppercase"}
                fontWeight={"500"}
                fontSize={"14px"}
                _active={{ background: "transparent", outline: "none" }}
                _hover={{ background: "transparent" }}
                _focus={{ outline: "none", background: "transparent" }}
              >
                {lang ? "Winery" : "Bodega"}
              </MenuButton>
              <MenuList
                background={"transparent"}
                border={"none"}
                borderRadius={"0px"}
                w={"95vw"}
                p={0}
              >
                <HStack
                  justifyContent={"center"}
                  borderTop={
                    location.pathname === "/contacto"
                      ? "1px solid #931414"
                      : "1 px solid#F8EFE7"
                  }
                  w={"fit-content"}
                  mx={"auto"}
                  px={5}
                  py={3}
                  spacing={4}
                >
                  <MenuItem
                    w={"unset"}
                    _active={{ background: "transparent", outline: "none" }}
                    _hover={{ background: "transparent", border: "none" }}
                    _focus={{ outline: "none", background: "transparent" }}
                  >
                    <Link to="/bodega">
                      <Text
                        _hover={{
                          background: "transparent",
                          border: "none",
                          fontWeight: "bold",
                        }}
                      >
                        {lang ? "The winery" : "La bodega"}
                      </Text>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    w={"unset"}
                    _active={{ background: "transparent", outline: "none" }}
                    _hover={{ background: "transparent", border: "none" }}
                    _focus={{ outline: "none", background: "transparent" }}
                  >
                    <Link to="/fincas">
                      <Text
                        _hover={{
                          background: "transparent",
                          border: "none",
                          fontWeight: "bold",
                        }}
                      >
                        {lang ? "Estates" : "Fincas"}
                      </Text>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    w={"unset"}
                    _active={{ background: "transparent", outline: "none" }}
                    _hover={{
                      background: "transparent",
                      border: "none",
                      fontWeight: "bold",
                    }}
                    _focus={{ outline: "none", background: "transparent" }}
                  >
                    <Link to="/equipo">
                      <Text
                        _hover={{
                          background: "transparent",
                          border: "none",
                          fontWeight: "bold",
                        }}
                      >
                        {lang ? "Our team" : "Nuestro equipo"}
                      </Text>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    w={"unset"}
                    _active={{ background: "transparent", outline: "none" }}
                    _hover={{
                      background: "transparent",
                      border: "none",
                      fontWeight: "bold",
                    }}
                    _focus={{ outline: "none", background: "transparent" }}
                  >
                    <Link to="/sustentabilidad">
                      <Text
                        _hover={{
                          background: "transparent",
                          border: "none",
                          fontWeight: "bold",
                        }}
                      >
                        {lang ? "Sustainability" : "Sustentabilidad"}
                      </Text>
                    </Link>
                  </MenuItem>
                  <MenuItem
                    w={"unset"}
                    _active={{ background: "transparent", outline: "none" }}
                    _hover={{
                      background: "transparent",
                      border: "none",
                      fontWeight: "bold",
                    }}
                    _focus={{ outline: "none", background: "transparent" }}
                  >
                    <Link to="/certificaciones">
                      <Text
                        _hover={{
                          background: "transparent",
                          border: "none",
                          fontWeight: "bold",
                        }}
                      >
                        {lang ? "Certifications" : "Certificaciones"}
                      </Text>
                    </Link>
                  </MenuItem>
                </HStack>
              </MenuList>
            </Menu>
            <Link to="/vinos">
              <Text fontWeight={"500"} textTransform={"uppercase"}>
                {lang ? "Wines" : "Vinos"}
              </Text>
            </Link>
            <Link to="/tours">
              <Text fontWeight={"500"} textTransform={"uppercase"}>
                {lang ? "Tourism" : "Turismo"}
              </Text>
            </Link>
            <Link to="/contacto">
              <Text fontWeight={"500"} textTransform={"uppercase"}>
                {lang ? "Contact" : "Contacto"}
              </Text>
            </Link>
          </HStack>
          <Box>
            <Menu>
              <MenuButton
                as={Button}
                p={{ md: "3", xs: 0 }}
                height={{ md: "40px", xs: "20px" }}
                background={"transparent"}
                color={
                  location.pathname === "/contacto" ||
                  location.pathname.includes("vino/")
                    ? "#931414"
                    : "#F8EFE7"
                }
                rightIcon={
                  location.pathname === "/contacto" ||
                  location.pathname.includes("vino/") ? (
                    <ChevronDownIconRed />
                  ) : (
                    <ChevronDownIcon />
                  )
                }
                fontWeight="500"
                letterSpacing={"0.03em"}
                fontFamily={"Montserrat"}
                fontSize={{ xs: "xs", md: "sm" }}
                bg="bgPrimary"
                _hover={{ backgroundColor: "transparent" }}
                _active={{
                  backgroundColor: "transparent",
                  outline: "none",
                  border: "none",
                }}
              >
                {lang === 0 ? "ES" : "EN"}
              </MenuButton>
              <MenuList
                background={"transparent"}
                color={"#fff"}
                border={"none"}
                minWidth={"50px"}
                borderTop={"1px solid #fff"}
                borderRadius={"0px"}
              >
                <MenuItem
                  onClick={() => {
                    setLang(0);
                    localStorage.setItem("lang", 0);
                  }}
                  _hover={{ background: "transparent" }}
                  _active={{ background: "transparent" }}
                  color={
                    location.pathname === "/contacto" ||
                    location.pathname.includes("vino/")
                      ? "#931414"
                      : "#F8EFE7"
                  }
                >
                  ES
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setLang(1);
                    localStorage.setItem("lang", 1);
                  }}
                  _hover={{ background: "transparent" }}
                  _active={{ background: "transparent" }}
                  color={
                    location.pathname === "/contacto" ||
                    location.pathname.includes("vino/")
                      ? "#931414"
                      : "#F8EFE7"
                  }
                >
                  EN
                </MenuItem>
              </MenuList>
            </Menu>
          </Box>
        </HStack>
      </Box>
      <Box
        h={"100vh"}
        background={"rojo"}
        position={"fixed"}
        left={"0"}
        top={"0"}
        w={{ md: "50%", xs: "70%" }}
        pt={16}
        transition={"all .4s ease-in-out"}
        zIndex={999}
        display={sideMenu ? "block" : "none"}
        className={"sidebar-animation"}
      >
        <Box d={"flex"} justifyContent="center" pb={10}>
          <Image src="./img/logo-dorado.png" w={"45%"} />
        </Box>
        <VStack spacing={8} alignItems={"flex-start"} pl={8}>
          <Box>
            <Text
              color={"crema"}
              fontFamily={"Montserrat"}
              fontSize={"14px"}
              lineHeight={"17px"}
              fontWeight={"600"}
              textTransform={"uppercase"}
              letterSpacing={"0.05em"}
              onClick={() => {
                setDesplegable(!desplegable);
              }}
            >
              {lang ? "Winery" : "Bodega"}
            </Text>
            <VStack
              px={desplegable ? 4 : 0}
              alignItems={"flex-start"}
              pt={desplegable ? 4 : 0}
              spacing={3}
              h={desplegable ? "fit-content" : "0"}
              transition={"all .4s ease-in-out"}
              opacity={desplegable ? "1" : "0"}
              color={"crema"}
            >
              <Link
                onClick={() => {
                  setSideMenu(false);
                }}
                to="/bodega"
              >
                <Text display={desplegable ? "initial" : "none"}>
                  {lang ? "The winery" : "La bodega"}
                </Text>
              </Link>
              <Link
                onClick={() => {
                  setSideMenu(false);
                }}
                to={"/fincas"}
              >
                <Text display={desplegable ? "initial" : "none"}>
                  {lang ? "Estates" : "Fincas"}
                </Text>
              </Link>
              <Link
                onClick={() => {
                  setSideMenu(false);
                }}
                to={"/equipo"}
              >
                <Text display={desplegable ? "initial" : "none"}>
                  {lang ? "Our team" : "Nuestro equipo"}
                </Text>
              </Link>
              <Link
                onClick={() => {
                  setSideMenu(false);
                }}
                to={"/sustentabilidad"}
              >
                <Text display={desplegable ? "initial" : "none"}>
                  {lang ? "Sustainability" : "Sustentabilidad"}
                </Text>
              </Link>
              <Link
                onClick={() => {
                  setSideMenu(false);
                }}
                to={"/certificaciones"}
              >
                <Text display={desplegable ? "initial" : "none"}>
                  {lang ? "Certifications" : "Certificaciones"}
                </Text>
              </Link>
            </VStack>
          </Box>
          <Link
            onClick={() => {
              setSideMenu(false);
            }}
            to={"/vinos"}
          >
            <Text
              color={"crema"}
              fontFamily={"Montserrat"}
              fontSize={"14px"}
              lineHeight={"17px"}
              fontWeight={"600"}
              textTransform={"uppercase"}
              letterSpacing={"0.05em"}
            >
              {lang ? "Wines" : "Vinos"}
            </Text>
          </Link>
          <Link
            onClick={() => {
              setSideMenu(false);
            }}
            to={"/tours"}
          >
            <Text
              color={"crema"}
              fontFamily={"Montserrat"}
              fontSize={"14px"}
              lineHeight={"17px"}
              fontWeight={"600"}
              textTransform={"uppercase"}
              letterSpacing={"0.05em"}
            >
              {lang ? "Tourism" : "Turismo"}
            </Text>
          </Link>
          <Link
            onClick={() => {
              setSideMenu(false);
            }}
            to={"/contacto"}
          >
            <Text
              color={"crema"}
              fontFamily={"Montserrat"}
              fontSize={"14px"}
              lineHeight={"17px"}
              fontWeight={"600"}
              textTransform={"uppercase"}
              letterSpacing={"0.05em"}
            >
              {lang ? "Contact" : "Contacto"}
            </Text>
          </Link>
        </VStack>
      </Box>
      <Box
        h={"100vh"}
        w={"100vw"}
        position={"fixed"}
        top={"0"}
        left={"0"}
        background={"#000"}
        zIndex={10}
        display={sideMenu ? "block" : "none"}
        opacity={".4"}
        onClick={() => setSideMenu(false)}
      ></Box>
    </Container>
  );
};

export default Navegacion;
